<template>
  <v-container>
    <v-row>
      <v-col class="mt-6 ml-4">
        <h1 class="display-1 font-weight-bold">ロール一覧</h1>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="roles"
          :loading="loading"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-btn @click="roleUpdate()" class="mr-2">
                <v-icon left> mdi-reload </v-icon>
                ユーザ数更新
              </v-btn>
              <v-btn color="primary" @click="roleImport()" class="mr-2">
                <v-icon left> mdi-tray-arrow-up </v-icon>
                一括登録
              </v-btn>
              <v-btn color="success" @click="roleExport()">
                <v-icon left> mdi-tray-arrow-down </v-icon>
                CSVダウンロード
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <router-link
              :to="{ name: 'UsersByRole', params: { id: item.id } }"
              style="text-decoration: none"
            >
              {{ item.name }}
            </router-link>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <export-roles-dialog ref="roleexport"></export-roles-dialog>
    <v-dialog v-model="updateDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pa-4">
          更新中...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import ExportRolesDialog from "@/components/ExportRolesDialog";

export default {
  components: {
    ExportRolesDialog,
  },
  data() {
    return {
      headers: [
        { text: "ロール名", value: "name" },
        { text: "説明", value: "description" },
        { text: "ユーザ数", value: "users_count" },
      ],
      loading: false,
      updateDialog: false,
      roles: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      const accessToken = await this.$auth.getTokenSilently();
      await axios
        .get("/api/roles/", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          this.roles = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    roleExport() {
      this.$refs.roleexport.open();
    },
    roleImport() {
      this.$router.push({ name: "ImportRoles" });
    },
    async roleUpdate() {
      this.updateDialog = true;
      const accessToken = await this.$auth.getTokenSilently();
      await axios
        .get("/api/roles-update", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(() => {
          this.fetch();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.updateDialog = false;
        });
    },
  },
};
</script>
